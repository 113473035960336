<template>
    <v-container fill-height text-center>
        <div class="d-flex justify-center flex-column w-100">
            <p class="mb-5">We are sorry, but it seems the page you requested does not currently exist.</p>
            <div><v-btn :to="{ name: 'welcome' }" color="primary">Go to Dashboard</v-btn></div>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'The404Page'
}
</script>
